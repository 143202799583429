@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}
body {
    font-size: 1.3333333333333333vw;
    font-family: 'Inter', sans-serif;
   }

   .lights-container{
    display: flex;
    justify-content: space-between;
    position: relative;
   }

   .lights-container img{
    width: 61em;
    height: 67em;
    position: absolute;
    top: -37em;
   }

   .left-light{
    left: -35em;
   }

   .right-light{
    right: -35em;
   }

   /* Max Font Size */
   @media screen and (min-width:1200px) {
    body {font-size: 16px;}
   }