footer{
    padding: 1em 2em;
    padding-top: 10em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8em;
    position: relative;
}
.footer-logo{
    width: 10.3em;
    height: 4.5em;
}

.footer-text{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.25em;  
    position: relative;
    z-index: 3;
}

.telegram-logo{
    background-color: #D9D9D9;
    width: 2.8em;
    height: 2.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.telegram-logo img{
width: 2.2em;
height: 1.625em;
}

.footer-img{
    width: 31em;
    height: 30em;
    position: absolute;
    bottom: 0;
    right: 0;
   }

   @media all and (max-width: 1440px){
    footer{
    padding-top: 5em;
    }
    .footer-logo{
        width: 10em;
        height: 4.3em;
    }
    .footer-img{
        width: 27em;
        height: 26em;
       }   
}

@media all and (max-width: 576px){
    footer{
        flex-direction: column;
    }
    .footer-logo{
        width: 20vw;
    }

    .footer-text{
        font-size: 3.2vw;  
    }

    .telegram-logo{
        display: none;
    }
    
}