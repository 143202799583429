.team-member{
    width: 16.875em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-member img{
    width: 16.875em;
    height: 16.875em;
}

.name{
    font-weight: 700;
    font-size: 2.5em;
    color: #fff;
    margin-top: 0.8em;
}

.job{
    font-weight: 500;
    font-size: 1.5em;
    color: #fff;
    text-align: center;
    margin-top: 0.5em;
}

@media all and (max-width: 1440px){
    .team-member{
        width: 15em;
    }
    
    .team-member img{
        width: 15em;
        height: 15em;
    }
    
    .name{
        font-size: 2.2em;
    }
    
    .job{
        font-size: 1.2em;
    }
      }

      @media all and (max-width: 960px){
        .team-member{
            width: 40%;
            margin-bottom: 3em;
        }

        .team-member img{
            width: 20em;
            height: 20em;
        }
        
          }

          @media all and (max-width: 576px){
            .team-member{
                width: 100%;
            }
    
            .team-member img{
                width: 50vw;
                height: 50vw;
                margin-top: 3vw;
            }
            .name{
                font-size: 6vw;
                margin-top: 1vw;
            }
            
            .job{
                font-size: 3vw;
                margin-top: 0;
            }
          }