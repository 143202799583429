.burger-menu-block{
    width: 100%;
    height: 70vh;
    background-color: #00080E;
    position: fixed;
    z-index: 9999;
    padding: 5vw;
    transition: 0.6s;
    top: 64px;
}

.menu-closed{
    transform: translate(-100%, 0px);
}

.menu-opened{
    transform: translate(0px, 0px);
}

.burger-list-item a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 6vw;
    line-height: 121%;
    text-align: center;
    color: #fff;
    text-decoration: none;
  }
    

  .burger-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    list-style: none;
  }

  .telegram-button-burger{
    color: #000 !important;
    background-color: #fff !important;
    margin-top: 15vw !important;
    cursor: pointer;
  }

  .burger-nav{
    text-align: center;
  }