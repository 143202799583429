.white-paper{
    margin-top: 4em;
}

.white-paper-title{
    font-weight: 800;
    font-size: 4em;
    color: #fff;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;
}

.white-paper-list{
    padding-left: 6em;
    list-style: none;
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.white-paper-list-item{
    font-weight: 500;
    color: #fff;
    font-size: 2.5em;
}

@media all and (max-width: 1440px){
   
    .white-paper-title{
        font-size: 3.6em;
    }
        
    .white-paper-list-item{
         font-size: 2em;
    }
}

@media all and (max-width: 576px){
   
    .white-paper-title{
        font-size: 7vw;
    }
        
    .white-paper-list-item{
         font-size: 3.5vw;
         position: relative;
         z-index: 4;
    }
}