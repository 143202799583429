.animation{
    position: relative;
    width: 18em;
    height: 14em;
}

@keyframes moveUpDown {
    0%,
    100% {
      transform: translateY(0) rotate(var(--rotate));
    }
    50% {
      transform: translateY(1em) rotate(var(--rotate));
    }
  }
  
  .square {
    margin: 1em;
    position: absolute;
    animation: moveUpDown 2s infinite;
  }
  
  @media all and (max-width: 1000px){
    .animation{
        position: relative;
        width: 24em;
        height: 15em;
    }
  }

  @media all and (max-width: 576px){
    .animation{
        position: relative;
        width: 24em;
        height: 17em;
    }
  }