.projects-container{
    padding-top: 4em;    
}

.projects-title{
    font-weight: 700;
    color: white;
    font-size: 4em;
    margin-bottom: 2.5em;
    padding-top: 50px;
    text-align: center;
}

.projects{
    display: flex;
    justify-content: center;
    gap: 8em;
}

@media all and (max-width: 1440px){
    .projects{
        gap: 4em;
    }
}

@media all and (max-width: 576px){
    .projects{
        flex-direction: column;
        align-items: center;
        gap: 10vw;
    }

    .projects-title{
        font-size: 7vw;
        margin-bottom: 10vw;
    }
}